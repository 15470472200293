import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/seo'
import Policy from '../components/Policy/policy'

const Terms = () => (
    <Layout>
      <SEO title="NagNag"></SEO>
      <Policy />
    </Layout>
  )
  
  export default Terms;
